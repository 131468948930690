/** @define banners;  weak */
.banners__link {
  position: relative;
  width: 100%;
  max-width: 425px;
  margin: 15px;
  color: #ffffff;
  font-size: 26px;
  line-height: 1.1;
  font-family: montserratsemibold, arial, Helvetica, sans-serif;
}
.banners__link:hover {
  color: #f59a00;
}
@media only screen and (min-width: 950px) {
  .banners__link--pneu {
    max-width: 880px;
  }
}
.banners__text {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 15px 40px;
  background: url("/images/right-arrow.png") no-repeat right 20px center;
}
/*# sourceMappingURL=css/banners.css.map */